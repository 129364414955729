<!--
 * @Author: wangyibin
 * @Date: 2022-03-21 13:46:18
 * @LastEditTime: 2022-09-07 15:59:20
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\components\Header.vue
-->
<template>
    <!-- <div :class="url==''?(flag?'display-none':'fixed'):''" ref="headModal">
        <div class="main-top">
            <div class="logo" @click="routeToHome"><img :src="url==''?logoFooter:logoHearder" alt=""></div>
            <NavList :url="url"/>
        </div>
    </div> -->
    <div ref="headModal" style="background:#1a76ba;position: relative;">
        <div class="test">
            <a-space>
                <a-button type="primary" @click="changType('home')" :hidden="true">首页版本一</a-button>
                <a-button type="primary" @click="changType('home1')" :hidden="true">首页版本二</a-button>
            </a-space>
        </div>
        <div class="main-top">
            <div class="logo" @click="routeToHome" style="font-size: 30px; color: #ffffff">经纬翼</div>
            <NavList :url="url"/>
            <div class="icon-box">
                <a href="mailto:cs@cluan.cn">
                    <img :src="iconemailhd" title="cs@cluan.cn" alt="email">
                </a>
                <img :src="iconphonehd" title="400-600-9100" alt="phone">
                <a href="https://globalwing.s4.udesk.cn/im_client/?web_plugin_id=30493" target="_blank">
                    <img :src="iconservicehd" title="在线客服" alt="service">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import NavList from './NavList'
export default {
    name: 'Header',
    components: {NavList},
    data() {
        return {
            url: '',
            flag: false
        }
    },
    watch: {
        $route(){
            this.url = this.$route.path.split('/')[1]
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        init(){
            this.$nextTick(()=>{
                this.getHeadHeigth()
                this.showTopNav()
            })
        },
        getHeadHeigth(){
            const headHeigth = this.$refs.headModal.offsetHeight
            this.$store.commit('SET_HAEDHEIGHT',headHeigth)
        },
        showTopNav(){
            window.addEventListener('scroll',()=>{
                const {footerTop} = this.$store.state
                let scrolltop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
                scrolltop > footerTop ? this.flag = true : this.flag = false
            })
        },
        changType(type){
            type==='home'?this.$router.replace('/'):this.$router.replace('/home1')
        }
    }
}
</script>

<style lang="less" scoped>
.main-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    padding: 5px 0;
    height: 70px;
    background: #1a76ba;
    box-sizing: border-box;
    .logo{
        width: 192px;
        overflow: hidden;
        cursor: pointer;
        img{
            width: 192px;
        }
    }
    .icon-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        img{
            margin-right: 20px;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
    }
}
.fixed{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-Index: 9999;
    background: #1a76ba;
    padding-bottom: 5px;
}
.display-none{
    display:none;
}
.test{
    position: absolute;
    top:20px;
    right:5px;
    display: flex;
    justify-content: flex-end;
    z-index: 9999999;
}
</style>
