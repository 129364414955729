/*
 * @Author: wangyibin
 * @Date: 2022-03-21 13:10:19
 * @LastEditTime: 2022-09-08 18:19:26
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navType: false,
    styleType: false,
    headHeight: null,
    footerTop: null,
    title: '',
    loading: false
  },
  getters: {
  },
  mutations: {
    SET_NAVTYPE(state,val){
      state.navType = val
    },
    SET_STYLETYPE(state,val){
      state.styleType = val
    },
    SET_HAEDHEIGHT(state,val){
      state.headHeight = val
    },
    SET_FOOTERTOP(state,val){
      state.footerTop = val
    },
    SET_TITLE(state,val){
      state.title = val
    },
    SET_LOADING(state,val){
      state.loading = val
    }
  },
  actions: {
  },
  modules: {
  }
})
