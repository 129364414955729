<!--
 * @Author: wangyibin
 * @Date: 2022-03-21 16:23:04
 * @LastEditTime: 2022-09-15 16:07:09
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\components\NavList.vue
-->
<template>
    <div class="nav-list" @mouseleave="handlerShowHide()">
        <ul>
            <router-link to="/" style="color:#fff;">首页</router-link>
            <li
                v-for="(link,index) in navList"
                :key="link.id"
                :class="index==int&&index==onInt?'active':''"
                @mouseover="handlerShowSub(index,link.name)"
                @mouseleave="handlerHideSub()"
            >
                {{link.title}}
                <div class="sub-block" v-show="isSub&&index==int">
                    <SubContent
                        :subData="subData"
                        :getUrl="getUrl"
                        :urlname="urlname"
                        :isSub.sync="isSub"
                    ></SubContent>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import SubContent from './SubContent'
export default {
    name: 'NavList',
    props: ['url'],
    components: {SubContent},
    data() {
        return {
            getUrl: this.url || '',
            urlname: '',
            navList: [
                {
                    id: '1',
                    title: '产品服务',
                    link: '#',
                    name: '#',
                    children:[
                        {id:'1-1',title:'服务查询',link:'productserves',name:'productserves'},
                        {id:'1-2',title:'运费试算',link:'freighttrial',name:'freighttrial'},
                    ]
                },
                // {
                //     id: '2',
                //     title: '新闻中心',
                //     link: '#',
                //     name: '#',
                //     children:[
                //         {id:'2-1',title:'公司动态',link:''},
                //         {id:'2-2',title:'行业新闻',link:''},
                //     ]
                // },
                {
                    id: '3',
                    title: '关于经纬翼科技',
                    link: '#',
                    name: '#',
                    children:[
                        {id:'3-1',title:'公司介绍',link:'companyintro',name:'companyintro'},
                        {id:'3-2',title:'人才招聘',link:'recruit',name:'recruit'},
                    ]
                },
                {
                    id: '4',
                    title: '政策与条款',
                    link: '#',
                    name: '#',
                    children:[
                        {id:'4-1',title:'隐私政策',link:'userprivacy',name:'userprivacy'},
                        {id:'4-2',title:'用户使用条款',link:'useruse',name:'useruse'},
                    ]
                },

                //#region
                // {
                //     id: '1',
                //     title: '关于经纬翼科技',
                //     link: '/about',
                //     name: 'about',
                //     children:[
                //         {id:'1-1',title:'公司介绍',link:'companyProfile'},
                //         {id:'1-2',title:'企业文化',link:'companyCulture'},
                //         {id:'1-3',title:'公司荣誉',link:'honor'},
                //         {id:'1-4',title:'合作伙伴',link:'partner'},
                //         {id:'1-5',title:'招贤纳士',link:'careers'}
                //     ]
                // },
                // {
                //     id: '2',
                //     title: '产品与服务',
                //     link: '/productserves',
                //     name: 'productserves',
                //     children:[
                //         {id:'2-1',title:'跨境专线',link:'crossborder'},
                //         {id:'2-2',title:'邮政小包',link:'postalpacket'},
                //         {id:'2-3',title:'国际快递',link:'internationalexpress'},
                //         {id:'2-4',title:'FBA转运',link:'FBAtransfer'},
                //         {id:'2-5',title:'SaaS服务',link:'SaaSservice'}
                //     ]
                // },
                // {
                //     id: '3',
                //     title: '我要下单',
                //     link: '/downorder',
                //     name: 'downorder',
                //     children:[]
                // },
                // {
                //     id: '4',
                //     title: '帮助中心',
                //     link: '/helpcenter',
                //     name: 'helpcenter',
                //     children:[
                //         {id:'4-1',title:'运费计算器',link:'shippingcalculator'},
                //         {id:'4-2',title:'订单追踪',link:'ordertracking'},
                //         {id:'4-3',title:'投诉建议',link:'comlaints'},
                //         {id:'4-4',title:'禁限寄递物品清单',link:'listofitems'}
                //     ]
                // },
                // {
                //     id: '5',
                //     title: '新闻中心',
                //     link: '/newscenter',
                //     name: 'newscenter',
                //     children:[
                //         {id:'5-1',title:'重要公告',link:'importantnotice'},
                //         {id:'5-2',title:'公司动态',link:'companynews'},
                //         {id:'5-3',title:'市场活动',link:'marketingactivity'},
                //         {id:'5-4',title:'行业资讯',link:'industryinformation'}
                //     ]
                // },
                // {
                //     id: '6',
                //     title: '政策与声明',
                //     link: '/policystatement',
                //     name: 'policystatement',
                //     children:[
                //         {id:'6-1',title:'网站使用条款',link:'websiteuse'},
                //         {id:'6-2',title:'隐私权政策',link:'privacypolicy'}
                //     ]
                // },
                // {
                //     id: '7',
                //     title: '联系我们',
                //     link: '/contactus',
                //     name: 'contactus',
                //     children:[
                //         {id:'7-1',title:'办公网点',link:'officenetwork'},
                //         {id:'7-2',title:'商务合作',link:'businesscooperation'}
                //     ]
                // }
                //#endregion
            ],
            int: null,
            onInt: null,
            isSub: false,
            subData: {
                title: '',
                children: []
            },
            style: {
                color: '#fff'
            }
        }
    },
    watch:{
        url(newVal){
            this.getUrl = newVal
            if(newVal==''){
                this.int=null
                this.onInt=null
            }
        }
    },
    methods: {
        //显示子菜单
        handlerShowSub(index,name){
            this.int = index
            this.onInt = index
            this.isSub = true
            this.subData.title = this.navList[index].title
            this.subData.children = this.navList[index].children
            this.urlname = name
        },
        //隐藏子菜单
        handlerHideSub(){
            this.int = null
        },
        //显示/隐藏子菜单
        handlerShowHide(){
            this.isSub = false
            this.onInt = null
        },
        //跳转相应的页面
        // handleGotoPage(name){
        //     console.log('name',name);
        //     this.routeToPage(name)
        //     this.isSub = false
        // }
    }
}
</script>

<style lang="less" scoped>
.nav-list{
    position: relative;
    left: 100px;
    ul{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 70px;
        box-sizing: border-box;
        margin-bottom: 0 !important;
        li{
            margin-left: 50px;
            height: 70px;
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: #fff;
            font-size: 16px;
            &.active{
                color: #fff !important;
                position: relative;
                &::after{
                    content: '';
                    background: #fff;
                    height: 3px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
            &:first-child{
                margin-left:0;
            }
            .sub-block{
                background: #1a76ba;
                position: absolute;
                top: 70px;
                left: -40px;
                z-index: 9999;
                width: 150px;
                border-radius: 5px;
                &::after{
                    width: 0px;
                    height: 0px;
                    border: 100px solid #000;
                    border-top-color: red;
                    border-bottom-color: transparent;
                    border-left-color: transparent;
                    border-right-color: transparent;
                }
            }
        }
    }
}
.fade-enter-active, .fade-leave-active {
      transition: opacity .5s
}
.fade-enter, .fade-leave-active {
      opacity: 0
}
</style>
