/*
 * @Author: wangyibin
 * @Date: 2022-03-21 13:10:18
 * @LastEditTime: 2022-09-14 16:47:16
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//解决点击本页面时报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
    return originalPush.call(this,location).catch(err=>err)
}

const routes = [{
    path: '/',
    name: 'home',
    component: () =>
        // import ('@/views/Home.vue')
    import ('@/views/Home1.vue')
},{
    path: '/home1',
    name: 'home1',
    component: () =>
        import ('@/views/Home1.vue')
},{
    path: '/companyintro',
    name: 'companyintro',
    component: () =>
        import ('@/views/CompanyIntro.vue')
},{
    path: '/productserves',
    name: 'productserves',
    meta: {
        keepAlive: true
    },
    component: () =>
        import ('@/views/ProductServes.vue')
},{
    path: '/freighttrial',
    name: 'freighttrial',
    component: () =>
        import ('@/views/FreightTrial.vue')
},{
    path: '/productdesc',
    name: 'productdesc',
    component: () =>
        import ('@/views/ProductDesc.vue')
},{
    path: '/recruit',
    name: 'recruit',
    component: () =>
        import ('@/views/Recruit.vue')
},{
    path: '/userprivacy',
    name: 'userprivacy',
    component: () =>
        import ('@/views/UserPrivacy.vue')
},{
    path: '/useruse',
    name: 'useruse',
    component: () =>
        import ('@/views/UserUse.vue')
},{
    path: '/embargoInfo',
    name: 'embargoInfo',
    component: () =>
        import ('@/views/EmbargoInfo.vue')
},{
    path: '/collectionInfo',
    name: 'collectionInfo',
    component: () =>
        import ('@/views/CollectionInfo.vue')
},{
    path: '/selfdeliveryInfo',
    name: 'selfdeliveryInfo',
    component: () =>
        import ('@/views/SelfdeliveryInfo.vue')
},{
    path: '/mailInfo',
    name: 'mailInfo',
    component: () =>
        import ('@/views/MailInfo.vue')
},{
    path: '/activity',
    name: 'activity',
    component: () =>
        import ('@/views/activity/index.vue')
},{
    path: '/freightcalculation',
    name: 'freightcalculation',
    component: () =>
        import ('@/views/activity/freightcalculation.vue')
},{
    path: '/page1',
    name: 'page1',
    component: () =>
        import ('@/views/activity/page1.vue')
},{
    path: '/page2',
    name: 'page2',
    component: () =>
        import ('@/views/activity/page2.vue')
},{
    path: '/page3',
    name: 'page3',
    component: () =>
        import ('@/views/activity/page3.vue')
}]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
