/*
 * @Author: wangyibin
 * @Date: 2022-04-14 12:01:39
 * @LastEditTime: 2022-09-07 13:58:00
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\plugins\custom_method.js
 */
const customMethod = {
    install(Vue){
        Vue.mixin({
            methods: {
                //返回首页
                routeToHome(obj){
                    obj.path=='/'?this.gotoTop():this.$router.push('/')
                },
                //跳转页面到指定模块
                routeToPage(pathname,link=''){
                    console.log('pathname',pathname);
                    if(link==''){
                        this.$router.push({path:`/${pathname}`})
                        this.gotoTop()
                    }else{
                        this.$router.push({path:`/${pathname}`,query:{link}})
                    }
                },
                //当前页面点击菜单跳转至指定模块
                specfyModule(name){
                    name&&this.$nextTick(()=>{
                        // setTimeout(()=>{
                        //     let top = document.querySelector(`#${name}`).offsetTop
                        //     window.scrollTo({
                        //         top,
                        //         behavior: 'smooth'
                        //     })
                        // },20)
                    })
                },
                //跳转到顶部
                gotoTop(){
                    let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
                    const timTop = setInterval(()=>{
                        document.body.scrollTop = document.documentElement.scrollTop = top-=70
                        if(top<=0){
                            clearInterval(timTop)
                        }
                    },10)
                }
            }
        })
    }
}
export default customMethod
