<!--
 * @Author: wangyibin
 * @Date: 2022-04-07 11:47:03
 * @LastEditTime: 2022-09-08 15:47:46
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\components\SubContent.vue
-->
<template>
    <div class="sub-content">
        <div class="sub-content-right">
            <ul class="sub-navlist">
                <li v-for="item in newSubData.children" :key="item.id" @click="handleGoto(item.link)">
                    <span>{{item.title}}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import 'animate.css'
export default {
    name: 'SubContent',
    props: {
        subData: {
            type: Object,
            default: ()=>{return {}}
        },
        getUrl: String,
        urlname: String
    },
    data() {
        return {

        }
    },
    computed: {
        newSubData(){
            return this.subData
        }
    },
    methods: {
        handleGoto(link,type){
            console.log('link', link);
            // console.log('type', type);
            // if(type=='more'){
            //     this.routeToPage(link,'')
            //     this.$emit('update:isSub',false)
            // }else{
            //     if(this.getUrl==this.urlname){
            //         this.specfyModule(link)
            //     }else{
            //         this.routeToPage(this.urlname,link)
            //     }
            // }
            this.routeToPage(link)
        }
    }
}
</script>

<style lang="less" scoped>
.sub-content{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    // padding: 80px 50px;
    color: #fff;
    .sub-content-left{
        // width: 60%;
        .content-txt{
            width: 59%;
            font-size: 12px;
            margin-top: 20px;
        }
        .link-more{
            font-size: 12px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .trans{
                display: flex;
                align-items: center;
                transition: all .3s;
                &:hover{
                    span{
                        display: inline-block;
                        transform:translate(10px,0) scale(1.2);
                        transition: all .3s;
                    }
                    img{
                        width: 68px;
                        height: 13px;
                        margin-right: 5px;
                        transform:translate(10px,0) scale(1.2);
                        transition: all .3s;
                    }
                }
            }
        }
    }
    .sub-content-right{
        // width: 40%;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .sub-navlist{
            // display: flex;
            // flex-wrap: wrap;
            width: 100%;
            li{
                // border-bottom: 1px solid #feffff;
                width: 100%;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                border-radius: 5px;
                font-size: 14px;
                cursor: pointer;
                // &:first-child{
                //     margin-top: 20px;
                // }
                // &:last-child{
                //     margin-bottom: 20px;
                // }
                &:hover{
                    background: rgb(39, 109, 250);
                    transition: all .5s;
                    span{
                        display: block;
                        // transform:translate(10px,-10px) scale(1.2);
                        // transition: all .5s;
                    }
                }
            }
        }
    }
}
</style>
