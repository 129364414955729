<!--
 * @Author: wangyibin
 * @Date: 2022-04-11 09:25:17
 * @LastEditTime: 2022-04-11 10:12:30
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\components\ContactUs.vue
-->
<template>
    <div class="contactus-content">
        <div class="content-body">
            <div class="content-body-txt">
                <div class="txt">
                    <div class="head">商务合作</div>
                    <div>经纬翼科技  以科技连接世界</div>
                </div>
                <div class="title">联系我们</div>
            </div>
        </div>
        <div class="goto">
            <div class="goto-img"><img :src="img" alt=""></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs',
    data() {
        return {
            img: require('@/assets/icon_go.png')
        }
    },
}
</script>

<style lang="less" scoped>
.contactus-content{
    width: 100%;
    background: #c1c1c1;
    position: relative;
    .content-body{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 70px;
        width: calc(100% - 70px);
        height: 400px;
        .content-body-txt{
            display: flex;
            .txt{
                color: #333;
                font-size: 22px;
                .head{
                    font-size: 26px;
                    font-weight: bold;
                }
            }
            .title{
                background: #0d1ef1;
                width: 240px;
                height:50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 26px;
                border-radius: 40px;
                margin-left: 40px;
            }
        }
    }
    .goto{
        width: calc(100% - 80px);
        padding-right: 80px;
        position: absolute;
        left:0;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
        .goto-img{
            padding-bottom: 50px;
            width: 245px;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
    }
}
</style>
