/*
 * @Author: wangyibin
 * @Date: 2022-04-14 11:00:45
 * @LastEditTime: 2022-09-15 11:33:05
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\plugins\imgurl.js
 */
const imgUrl = {
    install(Vue){
        Vue.mixin({
            data() {
                return {
                    aboutHeadImg: require('@/assets/about_head_img.png'),
                    logoBigImg: require('@/assets/logo_big_img.png'),
                    logoHearder: require('@/assets/logo_header.png'),
                    logoFooter: require('@/assets/logo_footer.png'),
                    logoFooterOld: require('@/assets/logo_footer_old.png'),
                    iconJiao: require('@/assets/icon_jiao.png'),
                    iconGo: require('@/assets/icon_go.png'),
                    iconConpreson: require('@/assets/icon_conpreson.png'),
                    iconEmail: require('@/assets/icon_email.png'),
                    iconPhone: require('@/assets/icon_phone.png'),
                    iconSite: require('@/assets/icon_site.png'),
                    iconTop: require('@/assets/icon_top.png'),
                    iconAsk: require('@/assets/icon_ask.png'),
                    rqcode: require('@/assets/gwt-rqcode.jpg'),
                    iconemailhd: require('@/assets/icon_email_hd.png'),
                    iconphonehd: require('@/assets/icon_phone_hd.png'),
                    iconservicehd: require('@/assets/icon_service_hd.png'),
                    iconContact: require('@/assets/icon_contact.png'),
                    iconTime: require('@/assets/icon_time.png'),
                    iconLocal: require('@/assets/icon_local.png'),
                    iconBack: require('@/assets/icon_back.png'),
                    icon17tra: require('@/assets/icon_17tra.png'),
                    v1: require('@/assets/v1.png'),
                    v2: require('@/assets/v2.png'),
                    v3: require('@/assets/v3.png'),
                    v4: require('@/assets/v4.png'),
                }
            },
        })
    }
}
export default imgUrl
