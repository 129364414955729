/*
 * @Author: wangyibin
 * @Date: 2022-04-14 10:51:16
 * @LastEditTime: 2022-04-14 12:03:45
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\plugins\index.js
 */
import imgUrl from "./imgurl"
import customMethod from "./custom_method"
const Plugins = {
    install:function(Vue){
        Vue.use(imgUrl)
        Vue.use(customMethod)
    }
}
export default Plugins