<!--
 * @Author: wangyibin
 * @Date: 2022-03-21 13:10:18
 * @LastEditTime: 2022-09-13 17:50:20
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\App.vue
-->
<template>
  <div id="app">
    <Header v-show="isShow"/>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
    <div style="background:#1a76ba;">
      <Footer v-show="isShow"/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {Header,Footer},
  data() {
    return {
      isShow:true
    }
  },
  created(){
    const {title} = this.$store.state
    this.setHtmlTitle(title)
    this.path = this.$route.path;
    if(this.path == '/activity'){
      this.isShow = false
    }
  },
  watch:{
    '$store.state.title'(newVal){
      if(newVal){
        this.setHtmlTitle(newVal)
      }
    },
    $route(to, from) {
      if(to.name === 'activity' || to.name == 'freightcalculation' || to.name == 'page1' || to.name == 'page2' || to.name == 'page3'){
        this.isShow = false
      }
    }
  },
  updated() { window.scroll(0, 0); },
  methods: {
    setHtmlTitle(name) {
      document.title = `${process.env.VUE_APP_TITLE}-${name}`
    }
  }

}
</script>


<style lang="less">
@import "@/assets/font/font.css";
*,html,body{
  margin:0;
  padding:0;
}
html,body{
  font-family: '阿里巴巴普惠体' !important;
  font-size: 16px !important;
}
a,ul,li,ol{
  list-style: none;
  text-decoration: none;
}
img{
  border:0;
  display: block;
}
.content{
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    @media screen and (min-width: 1921px) {
      width: 1400px;
    }
    .content-body{
        padding: 50px 70px;
        .body-img{
            padding: 100px 60px 50px 50px;
        }
    }
}
.ant-btn-primary{
    background: #297fd5;
}
</style>
