/*
 * @Author: wangyibin
 * @Date: 2022-09-07 18:57:58
 * @LastEditTime: 2022-09-08 15:07:12
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\utils\rem.js
 */
const baseSize = 16
function setRem(){
  const scale = document.documentElement.clientWidth / 750
  // document.documentElement.style.fontSize = baseSize * Math.min(scale,2) + 'px'
  document.documentElement.style.fontSize = baseSize + 'px'
}
setRem()
window.onresize = function(){setRem()}
