/*
 * @Author: wangyibin
 * @Date: 2022-03-21 13:10:18
 * @LastEditTime: 2022-09-07 19:00:32
 * @LastEditors: wangyibin
 * @FilePath: \gwt-website\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Plugins from '@/plugins'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'animate.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import { ImagePreview } from 'vant';
import '@/utils/rem'

Vue.config.productionTip = false
Vue.use(ImagePreview);
Vue.use(Plugins)
Vue.use(Vant);
Vue.use(Antd)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
